import React from 'react';
import { About } from '../components/About';
import { PublicLayout } from '../components/Shared/PublicLayout';
import SeoHeader from '../components/Shared/SeoHeader';

export default function AboutPage() {
  return (
    <PublicLayout>
      <SeoHeader title="About NSTW" />
      <About />
    </PublicLayout>
  );
}
