import React, { useEffect, useState } from 'react';
import { m, LazyMotion, domAnimation, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type Props = {
  // isShow: boolean;
  position: 'left' | 'right';
  body: string;
  index: number;
};

export function TimelineItem({ position, body, index }: Props) {
  const [isShow, setIsShow] = useState<boolean>(false);
  const animation = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (!isShow && inView) setIsShow(true);
  }, [inView]);

  useEffect(() => {
    if (isShow) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      });
    }

    if (!isShow) {
      animation.start({ opacity: 0 });
    }
  }, [isShow]);

  return (
    <div
      className={`flex ${
        position === 'left' ? 'flex-row-reverse' : ''
      } md:contents`}
    >
      {position === 'right' ? (
        <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
          <div className="h-full w-4 flex items-center justify-center">
            <div
              className={`h-full w-1 bg-primary-${index} pointer-events-none`}
            ></div>
          </div>
          <div
            className={`w-4 h-4 absolute top-1/2 -mt-3 rounded-full bg-primary-${index} shadow`}
          ></div>
        </div>
      ) : null}

      <LazyMotion features={domAnimation}>
        <m.div
          ref={ref}
          animate={animation}
          className={`
								p-6 rounded-xl  
								my-2 shadow-md
								${
                  position === 'left'
                    ? 'col-start-1 col-end-5 ml-auto'
                    : 'col-start-6 col-end-10 mr-auto'
                }
                border border-gray-100 bg-white
                cursor-pointer transform hover:-translate-y-0.5 transition-all duration-150
                `}
        >
          <p className="leading-tight text-gray-500 text-justify text-sm">
            {body}
          </p>
        </m.div>
      </LazyMotion>

      {position === 'left' ? (
        <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
          <div className="h-full w-4 flex items-center justify-center">
            <div
              className={`h-full w-1 bg-primary-${index} pointer-events-none`}
            ></div>
          </div>
          <div
            className={`w-4 h-4 absolute top-1/2 -mt-3 rounded-full bg-primary-${index} shadow`}
          ></div>
        </div>
      ) : null}
    </div>
  );
}
