import React, { useEffect } from 'react';
import { SectionHeader } from '../Shared/SectionHeader';
import { TimelineItem } from '../Shared/Timeline/TimelineItem';
import { Wrapper } from '../Shared/Wrapper';

export function About() {
  return (
    <section className="py-12" id="about">
      <Wrapper>
        <SectionHeader title="SCIENCE FOR THE PEOPLE" align="center" />
        <p className="text-center w-full md:w-6/12 mx-auto mb-10 text-lg">
          The NSTW highlights the significant contributions of science and
          technology to national development and has become a platform for
          heralding S&T advocacy in the country.
        </p>

        <div className="container w-full md:w-8/12 mx-auto overflow-hidden">
          <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
            <TimelineItem
              position="left"
              body={
                "In the 1950's, the National Science Development Board (forerunner of DOST) spearheaded the Philippine National Science Week celebration on the third week of November. It became a backdrop for conferring recognition and appreciation to outstanding scientists, researchers, inventors, and institutions. "
              }
              index={1}
            />
            <TimelineItem
              position="right"
              body={
                'The NSTW was institutionalized in 1982 through Proclamation No. 2214 signed by President Ferdinand E. Marcos, which designated the second week of July each year for its observance.'
              }
              index={1}
            />
            <TimelineItem
              position="left"
              body={
                'The Science and Technology Master Plan (STMP) was launched by the administration of former Pres. Corazon C. Aquino during the 32nd NSTW celebration in 1990 to establish the desired direction of S&T development in the country. The S&T Fair was included in the line-up of important activities of the NSTW during the same year. It became the banner activity throughout the 1990s.'
              }
              index={1}
            />
            <TimelineItem
              position="right"
              body={
                'In 1993, the NSTW celebration was moved to the third week of July through Proclamation No. 169 under the administration of former Pres. Fidel V. Ramos.'
              }
              index={1}
            />
            <TimelineItem
              position="left"
              body={
                'In August 2019, by virtue of Proclamation 780 signed by President Rodrigo “Roa” Duterte, the NSTW celebration will now be conducted every fourth week of November. The       change of date was meant to ensure “maximum participation” of schools, students, stakeholders, and the public during the week-long celebration due to the change in the       academic calendar of most universities, schools, and educational institutions.'
              }
              index={1}
            />
            <TimelineItem
              position="right"
              body={
                'Other important activities of the NSTW include the various awards for basic and applied research, technology transfer, technology commercialization and S&T promotion;       scientific meetings, technical and investment forums, technology demonstrations, and technical tours.'
              }
              index={1}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
}
