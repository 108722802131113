import React from 'react';

interface Props {
  title: string;
  align: 'center' | 'left' | 'right';
  description?: JSX.Element;
}

export function SectionHeader({ title, description, align = 'left' }: Props) {
  return (
    <section className={`text-${align} mb-10`}>
      <h1 className="text-4xl md:text-6xl text-primary-2 font-hero-rounded">
        {title}
      </h1>
      {typeof description !== 'undefined' && (
        <p className="text-md">{description}</p>
      )}
    </section>
  );
}
